import { Button, Checkbox, Modal, Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const WorkshopCheckModal = ({
    open,
    intl,
    isMobile,
    orderId,
    rowData,
    selectedRows,
    hideModal,
    updateLabor,
    updateMultiple,
    user
}) => {
    const [loading, setLoading] = useState(false);
    const [workshopChecks, setWorkshopChecks] = useState([]);
    const [finish, setFinish] = useState(false);

    const updateServiceCheckById = (serviceId, checkName, newCheckData) => {
        setWorkshopChecks(prevData => {
            const newServices = prevData.map(service => {
                if (service.serviceId !== serviceId) return service;

                const newChecks = service.seviceChecks.map(check =>
                    check.check == checkName ? { ...check, ...newCheckData } : check
                );

                return { ...service, seviceChecks: newChecks };
            });

            return newServices;
        });
    };

    const expandedRowRender = (parentRecord, index) => {
        const columns = [
            {
                key: 'name',
                width: '160px',
                align: 'center',
                render: record => <div>{record.check}</div>
            },
            {
                key: 'checked',
                width: '160px',
                align: 'center',
                render: (text, record, checkIndex) => (
                    <Checkbox
                        checked={record.checked}
                        onChange={event => {
                            updateServiceCheckById(parentRecord.serviceId, record.check, {
                                ...record,
                                checked: event.target.checked
                            });
                        }}
                    />
                )
            },
            {
                key: 'name',
                width: '160px',
                align: 'center',
                render: record => <div>{record.value}</div>
            }
        ];

        const filtered = parentRecord.seviceChecks.filter((item, index, self) => {
            const key = item.checkRowId;

            return index === self.findIndex(i => i.checkRowId === key);
        });

        return (
            <Table
                className={Styles.innerTable}
                columns={columns}
                dataSource={filtered}
                pagination={false}
                rowKey={record => record.checkRowId}
                // rowKey={record => {
                //     return console.log(record, 'rec');
                //     // record.check
                // }}
                showHeader={false}
            />
        );
    };

    const onClose = () => {
        setWorkshopChecks([]);
        setFinish(false);
        hideModal();
    };

    const onFinish = useCallback(async () => {
        setLoading(true);

        try {
            const seviceChecks = workshopChecks.flatMap(service =>
                service.seviceChecks.map(check => ({
                    serviceId: service.serviceId,
                    checkRowId: check.checkRowId
                }))
            );

            const finishChecks = workshopChecks.flatMap(finish =>
                finish.seviceChecks.map(check => ({
                    checkRowId: check.checkRowId
                }))
            );

            await fetchAPI(
                'POST',
                '/orders/workshop/checks',
                null,
                { orderId, seviceChecks: !finish ? seviceChecks : [], finishChecks: finish ? finishChecks : [] },
                {
                    handleErrorInternally: true
                }
            );

            if (rowData) {
                updateLabor(rowData.key, rowData, 'DONE');
            } else {
                updateMultiple('DONE');
            }

            onClose();

            setLoading(false);
        } catch (err) {
            setLoading(false);
            hideModal();
        }
    }, [finish, hideModal, onClose, orderId, rowData, updateLabor, updateMultiple, workshopChecks]);

    const groupByService = checks => {
        const map = new Map();

        checks.forEach(({ serviceId, serviceName, stage, ...checkData }) => {
            if (!map.has(serviceId)) {
                map.set(serviceId, {
                    serviceId: 0,
                    serviceName: intl.formatMessage({ id: 'workshop_table.button.finish' }),
                    seviceChecks: []
                });
            }

            map.get(serviceId).seviceChecks.push(checkData);
        });

        return Array.from(map.values());
    };

    const fetchChecks = useCallback(async () => {
        setLoading(true);

        const selectedIds = selectedRows?.map(({ id }) => id);

        const response = await fetchAPI(
            'GET',
            '/orders/workshop/checks',
            { orderId, ids: rowData ? [rowData.id] : selectedIds },
            null,
            {
                handleErrorInternally: true
            }
        );

        if (!response.services?.length && !response.finishChecks?.length) {
            onFinish();
        }

        if (response.finishChecks?.length) {
            const finishChecks = await groupByService(response.finishChecks);
            await setWorkshopChecks(finishChecks);
            setFinish(true);
        }
        if (response.services?.length) {
            await setWorkshopChecks(response.services);
        }

        setLoading(false);
    }, [orderId, rowData, selectedRows]);

    useEffect(() => {
        if (open) {
            fetchChecks();
        }
    }, [fetchChecks, open]);

    const columns = [
        // {
        //     title: '№',
        //     key: 'count',
        //     width: '1%',
        //     render: (key, row, index) => {
        //         return `${index + 1}.`;
        //     }
        // },
        {
            title: <FormattedMessage id='to_check' />,
            dataIndex: 'serviceName',
            key: 'name',
            align: 'center',
            width: '160px',
            className: Styles.bold
        },
        {
            title: <FormattedMessage id='settings_page.checked' />,
            // dataIndex: 'serviceName',
            key: 'check',
            align: 'center',
            width: '160px'
        },
        {
            title: <FormattedMessage id='info_modal.value' />,
            key: 'value',
            align: 'center',
            width: '160px'
        }
    ];

    const isAnyUnchecked = workshopChecks?.some(service => service.seviceChecks.some(check => check.checked === false));
    const filteredData = workshopChecks?.filter((item, index, self) => {
        const key = item.serviceId;

        return index === self.findIndex(i => i.serviceId === key);
    });

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            okButtonProps={{
                disabled: isAnyUnchecked
            }}
            onCancel={onClose}
            onOk={async () => {
                await onFinish();
            }}
            open={open}
            title={intl.formatMessage({
                id: 'repair_map_setting.workshop_check'
            })}
            width={isMobile ? '95%' : '60%'}
        >
            {workshopChecks && workshopChecks?.length ? (
                <React.Fragment>
                    <Space className={Styles.buttonContainerStyles}>
                        <Button onClick={onClose}>{intl.formatMessage({ id: 'cancel' })}</Button>
                        <Button
                            disabled={isAnyUnchecked}
                            // disabled={blocked}
                            onClick={async () => {
                                await onFinish();
                            }}
                            type='primary'
                        >
                            {intl.formatMessage({ id: 'ok' })}
                        </Button>
                    </Space>

                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        expandable={{
                            expandedRowRender,
                            defaultExpandAllRows: true

                            // expandIcon: () => null
                        }}
                        // expandable={{ expandedRowRender, defaultExpandAllRows: true, expandIcon: () => null }}
                        loading={loading}
                        pagination={false}
                        rowKey={record => record.serviceId}
                        scroll={{ x: 100 }}
                        size='small'
                    />
                </React.Fragment>
            ) : (
                <Spin />
            )}
        </Modal>
    );
};

export default injectIntl(WorkshopCheckModal);
