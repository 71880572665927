import { AutoComplete, Button, Input, InputNumber, Modal, Select, Tooltip, notification } from 'antd';
import { MODALS } from 'core/modals/duck';
import _ from 'lodash';
import { DetailCatalogueModal, WMSCellsModal } from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StockIcon } from 'theme';
import { buildStoreGroupsTree, fetchAPI, numeralFormatter, numeralParser } from 'utils';

import * as constants from 'pages/Storage/constants';
import AddStoreProductModal from './AddStoreProductModal';
import Styles from './styles.m.css';

const { Option } = Select;
const { TextArea } = Input;

@injectIntl
class AddProductModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            editMode: false,
            alertModalVisible: false,
            storeGroupsTree: [],
            storageProducts: [],
            brandSearchValue: '',
            visible: false,
            showCellModal: false,
            showFromCellModal: false,
            brandId: undefined,
            brandName: undefined,
            detailCode: undefined,
            groupId: undefined,
            tradeCode: undefined,
            detailName: undefined,
            uktz: undefined,
            addToAddress: undefined,
            getFromAddress: undefined,
            sellingPrice: 0,
            stockPrice: 0,
            quantity: 1,
            docProductUnitId: 1,
            orderId: undefined,
            detailCatalogueFormDocs: true,
            comment: undefined,
            // We’ll keep derived sums here too, purely to allow *editing* them. They are normally computed as
            // quantity * purchasePrice (stockPrice) and quantity * sellingPrice. This will let you store any
            // “in-progress” changes when the user modifies the sum input.
            purchaseSum: 0,
            sellingSum: 0,

            storageBalance: [
                { messageId: 'storage.in_stock', count: 0 },
                { messageId: 'storage.reserve', count: 0 },
                { messageId: 'storage.in_orders', count: 0 },
                { messageId: 'storage.ordered', count: 0 },
                { messageId: 'storage.deficit', count: 0 },
                { messageId: 'storage.min', count: 0 },
                { messageId: 'storage.max', count: 0 },
                { messageId: 'storage.to_order', count: 0 }
            ]
        };

        // We store reference to the debounced function for searching
        this.debouncedQuerySearch = _.debounce(value => {
            this.getStorageProductsByQuery(value);
        }, 1000);

        this.confirmAlertModal = this.confirmAlertModal.bind(this);
        this.cancelAlertModal = this.cancelAlertModal.bind(this);
    }

    // -------------------------------------
    // Lifecycle & Data Fetching
    // -------------------------------------

    componentDidMount() {
        this.getStoreGroups();
    }

    componentDidUpdate(prevProps, prevState) {
        const { product, operationCode } = this.props;

        // When we open the modal with a product to edit
        if (!prevProps.visible && this.props.visible) {
            if (product) {
                const { quantity, stockPrice, sellingPrice } = product;
                this.setState({
                    editMode: true,
                    brandId: product.brandId,
                    brandName: product.brandName,
                    detailCode: product.detailCode,
                    tradeCode: product.tradeCode,
                    groupId: product.groupId,
                    detailName: product.detailName,
                    uktz: product.uktz,
                    stockPrice,
                    quantity,
                    productId: product.productId,
                    ordersAppurtenancies: product.ordersAppurtenancies,
                    sellingPrice,
                    addToAddress: product.addToAddress,
                    getFromAddress: product.getFromAddress,
                    comment: product.comment,
                    orderId: product.orderId,
                    sourceForProduct: product.product?.source,
                    // Initialize sums
                    purchaseSum: (quantity || 0) * (stockPrice || 0),
                    sellingSum: (quantity || 0) * (sellingPrice || 0)
                });
            }
        }

        // If product changed in state, and operationCode is SRT, set sellingPrice to stockPrice
        if (prevState.productId !== this.state.productId && operationCode === 'SRT' && !product) {
            this.setState({
                sellingPrice: this.state.stockPrice,
                sellingSum: this.state.quantity * this.state.stockPrice
            });
        }
    }

    // -------------------------------------
    // Fetch data
    // -------------------------------------

    getStoreGroups = async () => {
        const storeGroups = await fetchAPI('GET', 'store_groups', null, null, {
            handleErrorInternally: true
        });
        buildStoreGroupsTree(storeGroups);
    };

    getStorageProductsByQuery = async query => {
        const { operationCode } = this.props;

        // The logic to handle operationCode remains the same.
        const params = { query, pageSize: 25, withoutPhoto: true };
        if (operationCode === 'PRI') {
            params.source = 'PRODUCTION';
        } else if (operationCode === 'DSO') {
            params.source = 'DISASSEMBLY';
        }
        const { list: storageProducts } = await fetchAPI('GET', 'store_products', params, null, {
            handleErrorInternally: true
        });
        this.setState({ storageProducts });
    };

    getProductId = async (detailCode, brandId, productId) => {
        const { type, documentType } = this.props;
        const { storageBalance, detailName, quantity } = this.state;
        let storageProduct = null;

        if (productId) {
            storageProduct = await fetchAPI('GET', `store_products/${productId}`, null, null, {
                handleErrorInternally: true
            });
        } else {
            const { list } = await fetchAPI(
                'GET',
                'store_products/',
                {
                    filterCode: detailCode,
                    brandId,
                    pageSize: 15
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            storageProduct = list.find(({ code, brandId: brand }) => code === detailCode && brand === brandId);
        }

        if (storageProduct) {
            let addToAddress = null;
            let getFromAddress = null;
            const preferAddress = _.get(storageProduct, 'cellAddresses[0]');

            if (type === constants.INCOME || documentType === constants.ORDERINCOME) {
                addToAddress = preferAddress;
            } else if (type === constants.EXPENSE || type === constants.TRANSFER) {
                getFromAddress = preferAddress;
            }

            storageBalance[0].count = storageProduct.countInWarehouses;
            storageBalance[1].count = storageProduct.reservedCount;
            storageBalance[2].count = storageProduct.countInOrders;
            storageBalance[3].count = storageProduct.countInStoreOrders;
            storageBalance[4].count = storageProduct.lack;
            storageBalance[5].count = storageProduct.min;
            storageBalance[6].count = storageProduct.max;
            storageBalance[7].count = storageProduct.quantity;

            const newQuantity = storageProduct.quantity || 1;
            const newStockPrice = storageProduct.stockPrice || storageProduct.purchasePrice || 0;
            const newSellingPrice = storageProduct.sellingPrice || 0;

            this.setState({
                detailCode: storageProduct.code,
                groupId: storageProduct.groupId,
                productId: storageProduct.id,
                detailName: storageProduct.name,
                uktz: storageProduct.uktz,
                brandId: storageProduct.brandId,
                brandName: storageProduct.brand && storageProduct.brand.name,
                tradeCode: storageProduct.tradeCode,
                quantity: newQuantity,
                stockPrice: newStockPrice,
                sellingPrice: newSellingPrice,
                addToAddress:
                    (storageProduct.defaultWarehouseId === this.props.warehouseId ? addToAddress : undefined) ||
                    storageProduct.getFromAddress,
                getFromAddress:
                    (storageProduct.defaultWarehouseId === this.props.warehouseId ? getFromAddress : undefined) ||
                    storageProduct.getFromAddress,
                orderId: storageProduct.orderId,
                docProductUnitId: storageProduct.productUnitId,
                unit: storageProduct.unit,
                sourceForProduct: storageProduct.source,
                // Initialize sums as well
                purchaseSum: newQuantity * newStockPrice,
                sellingSum: newQuantity * newSellingPrice
            });

            return true;
        }

        // If not found
        storageBalance.forEach(item => (item.count = 0));
        this.setState({
            groupId: undefined,
            productId: undefined,
            detailName: this.props.warning ? detailName : undefined,
            quantity: quantity || 1
        });

        return false;
    };

    // -------------------------------------
    // Utility
    // -------------------------------------

    handleCancel = () => {
        this.setState({
            brandSearchValue: '',
            visible: false,
            brandId: undefined,
            brandName: undefined,
            detailCode: undefined,
            groupId: undefined,
            productId: undefined,
            tradeCode: undefined,
            detailName: undefined,
            uktz: undefined,
            addToAddress: undefined,
            getFromAddress: undefined,
            showCellModal: false,
            showFromCellModal: false,
            purchasePrice: 0,
            sellingPrice: 0,
            stockPrice: 0,
            quantity: 1,
            editMode: false,
            comment: undefined,
            orderId: undefined,
            docProductUnitId: undefined,
            name: undefined,
            loading: false,
            storeGroupId: undefined,
            sourceForProduct: undefined,
            purchaseSum: 0,
            sellingSum: 0
        });
        this.props.hideModal();
    };

    loadingTrue = () => {
        this.setState({
            loading: true
        });
    };

    // -------------------------------------
    // Sums & Price Recalculation
    // -------------------------------------

    /** If user changes purchaseSum, keep quantity the same and recalc stockPrice. */
    handlePurchaseSumChange = newPurchaseSum => {
        const { quantity } = this.state;
        if (!quantity || quantity <= 0) {
            // If quantity is zero, you might decide to do something else, e.g. recalc quantity from price, or block.
            // For simplicity, if quantity=0, do nothing or set quantity=1. You can decide your own logic here.
            this.setState({ purchaseSum: newPurchaseSum });

            return;
        }
        const newStockPrice = newPurchaseSum / quantity;
        this.setState({
            purchaseSum: newPurchaseSum,
            stockPrice: newStockPrice
        });
    };

    /** If user changes sellingSum, keep quantity the same and recalc sellingPrice. */
    handleSellingSumChange = newSellingSum => {
        const { quantity } = this.state;
        if (!quantity || quantity <= 0) {
            this.setState({ sellingSum: newSellingSum });

            return;
        }
        const newSellingPrice = newSellingSum / quantity;
        this.setState({
            sellingSum: newSellingSum,
            sellingPrice: newSellingPrice
        });
    };

    // -------------------------------------
    // Alert Confirm / Cancel
    // -------------------------------------

    confirmAlertModal() {
        const { setModal } = this.props;
        const {
            detailCode,
            name,
            brandId,
            brandName,
            sellingPrice,
            stockPrice,
            quantity,
            getFromAddress,
            addToAddress,
            orderId,
            uktz,
            docProductUnitId,
            storeGroupId
        } = this.state;

        setModal(MODALS.STORE_PRODUCT, {
            brandId,
            brandName,
            groupId: storeGroupId || 1000000,
            code: detailCode,
            productUnitId: docProductUnitId,
            name,
            tradeCode: uktz,
            uktz,
            onClose: async () => {
                await this.cancelAlertModal();
                this.setState({
                    loading: false
                });
            },
            onSubmit: async id => {
                try {
                    const detail = await fetchAPI('GET', `store_products/${id}`);
                    await this.props.addDocProduct({
                        productId: id,
                        detailCode: detail.code,
                        brandName: detail.brand.name,
                        brandId: detail.brand.id,
                        tradeCode: detail.tradeCode,
                        detailName: detail.name,
                        getFromAddress,
                        addToAddress,
                        uktz: detail.uktz,
                        stockPrice: Number(stockPrice || 0),
                        sellingPrice: Number(sellingPrice || 0),
                        quantity: quantity || 1,
                        orderId,
                        docProductUnitId
                    });
                    this.handleCancel();
                } catch (error) {
                    this.setState({
                        loading: false
                    });
                    notification.error({
                        message: this.props.intl.formatMessage({ id: 'storage_document.error.is_already_exist' })
                    });
                }
            }
        });
        this.setState({
            alertModalVisible: false
        });
    }

    cancelAlertModal() {
        this.setState({
            alertModalVisible: false,
            loading: false
        });
    }

    // -------------------------------------
    // Add / Edit doc product
    // -------------------------------------

    handleOk = async () => {
        const {
            intl: { formatMessage },
            product,
            operationCode
        } = this.props;
        const {
            editMode,
            brandId,
            brandName,
            detailCode,
            tradeCode,
            groupId,
            detailName,
            uktz,
            stockPrice,
            quantity,
            productId,
            sellingPrice,
            addToAddress,
            getFromAddress,
            comment,
            orderId,
            docProductUnitId,
            sourceForProduct
        } = this.state;

        try {
            // Additional checks for operationCode
            if (sourceForProduct !== 'PRODUCTION' && operationCode === 'PRI') {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.message.PRI' })
                });

                return;
            }
            if (sourceForProduct !== 'DISASSEMBLY' && operationCode === 'DSO') {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.message.DSO' })
                });

                return;
            }

            if (!brandId || !detailCode) {
                notification.error({
                    message: formatMessage({ id: 'storage_document.error.required_fields' })
                });

                return;
            }

            const isProduct = productId
                ? true
                : await fetchAPI(
                      'GET',
                      'store_products/',
                      {
                          filterCode: detailCode,
                          brandId,
                          pageSize: 15
                      },
                      null,
                      { handleErrorInternally: true }
                  );

            if (!productId) {
                isProduct.list = _.get(isProduct, 'list', []).filter(
                    ({ code, brandId: brand }) => code === detailCode && brand === brandId
                );
            }

            const storageProductFind =
                _.get(isProduct.list, 'length', []) && isProduct !== true
                    ? isProduct.list.find(({ code, brandId: brand }) => code === detailCode && brand === brandId)
                    : undefined;
            // If found an existing store product, just add to doc
            if (storageProductFind) {
                await this.props.addDocProduct({
                    productId: storageProductFind.id,
                    detailCode: storageProductFind.id,
                    brandName: storageProductFind.brandName,
                    brandId: storageProductFind.brandId,
                    tradeCode: tradeCode || storageProductFind.tradeCode,
                    detailName: detailName || storageProductFind.name,
                    uktz: uktz || storageProductFind.uktz,
                    stockPrice: stockPrice || storageProductFind.stockPrice,
                    sellingPrice: sellingPrice || storageProductFind.sellingPrice,
                    quantity: quantity || storageProductFind.quantity,
                    groupId: groupId || storageProductFind.groupId,
                    sum: quantity * stockPrice,
                    sellingSum: quantity * sellingPrice,
                    addToAddress,
                    getFromAddress,
                    ordersAppurtenancies: storageProductFind.ordersAppurtenancies || null,
                    comment,
                    orderId,
                    docProductUnitId: docProductUnitId || storageProductFind.productUnitId
                });
                this.handleCancel();
            } else if (!isProduct || !_.get(isProduct.list, 'length', [])) {
                this.setState({
                    alertModalVisible: true,
                    loading: true
                });

                // Attempt to fetch from external search
                const { listParts } = await fetchAPI(
                    'GET',
                    'products/search/v2/m3',
                    {
                        code: detailCode,
                        brandId: brandId === 8000 ? undefined : brandId,
                        page: 1,
                        pageSize: 1
                    },
                    null,
                    { handleErrorInternally: true }
                );

                if (listParts && listParts.length) {
                    this.setState({
                        detailName: listParts[0].name,
                        name: listParts[0].name,
                        storeGroupId: listParts[0].storeGroupId
                    });
                }
            } else if (editMode) {
                this.setState({ loading: true });
                await this.props.editDocProduct(this.props.product.key, {
                    productId,
                    detailCode,
                    brandName,
                    brandId,
                    tradeCode,
                    detailName,
                    uktz,
                    stockPrice,
                    sellingPrice,
                    quantity,
                    groupId,
                    sum: quantity * stockPrice,
                    sellingSum: quantity * sellingPrice,
                    addToAddress,
                    getFromAddress,
                    ordersAppurtenancies: product.ordersAppurtenancies,
                    comment,
                    orderId,
                    docProductUnitId
                });
                this.handleCancel();
            } else {
                this.setState({ loading: true });
                await this.props.addDocProduct({
                    productId,
                    detailCode,
                    brandName,
                    brandId,
                    tradeCode,
                    detailName,
                    uktz,
                    stockPrice,
                    quantity,
                    groupId,
                    sellingPrice,
                    sum: quantity * stockPrice,
                    sellingSum: quantity * sellingPrice,
                    addToAddress,
                    getFromAddress,
                    comment,
                    orderId,
                    docProductUnitId
                });
                this.handleCancel();
            }
        } catch (error) {
            this.setState({ loading: false });
            console.error(error);
            notification.error({
                message: this.props.intl.formatMessage({ id: 'storage_document.error.is_already_exist' })
            });
        }
    };

    // -------------------------------------
    // Product selection from modals
    // -------------------------------------

    selectProduct = async product => {
        const { cells, type, documentType } = this.props;
        const { storageBalance } = this.state;

        if (!product) return;

        let addToAddress;
        let getFromAddress;
        const preferAddress = _.get(product, 'cellAddresses[0]');
        if (type === constants.INCOME || documentType === constants.ORDERINCOME || type === constants.TRANSFER) {
            addToAddress = preferAddress;
        } else if (type === constants.EXPENSE) {
            getFromAddress = product.cellAddress || preferAddress;
        }

        storageBalance[0].count = product.countInWarehouses;
        storageBalance[1].count = product.reservedCount;
        storageBalance[2].count = product.countInOrders;
        storageBalance[3].count = product.countInStoreOrders;
        storageBalance[4].count = product.lack;
        storageBalance[5].count = product.min;
        storageBalance[6].count = product.max;
        storageBalance[7].count = product.quantity;

        const newQuantity = product.quantity || 1;
        const newStockPrice = product.purchasePrice || 0;
        const newSellingPrice = product.price || 0;

        this.setState({
            productId: product.id || product.productId,
            brandId: product.brandId || product.supplierBrandId,
            brandName: product.brand?.name,
            detailCode: product.productCode,
            detailName: product.name,
            name: product.name,
            storeGroupId: product.storeGroupId,
            uktz: product.uktz,
            tradeCode: product.tradeCode,
            stockPrice: Math.round(newStockPrice * 10000) / 10000,
            sellingPrice: Math.round(newSellingPrice * 10000) / 10000,
            quantity: newQuantity,
            addToAddress:
                (product.defaultWarehouseId === this.props.warehouseId ? addToAddress : undefined) ||
                product.getFromAddress,
            getFromAddress:
                (product.defaultWarehouseId === this.props.warehouseId ? getFromAddress : undefined) ||
                product.getFromAddress,
            docProductUnitId: product.productUnitId,
            unit: product.unit,
            sourceForProduct: product.source,
            // derived sums
            purchaseSum: newQuantity * newStockPrice,
            sellingSum: newQuantity * newSellingPrice
        });
    };

    setSupplier = async item => {
        const {
            businessSupplierId,
            retailPrice,
            supplierBrandId,
            purchasePrice,
            supplierOriginalCode,
            partNumber,
            id,
            itemName
        } = item;

        // If you want to compute sums right away, do it here:
        const quantity = 1;
        const newStockPrice = Math.round(purchasePrice * 10000) / 10000;
        const newSellingPrice = Math.round(retailPrice * 10000) / 10000;

        await this.setState({
            productId: id || null,
            brandId: businessSupplierId || supplierBrandId || null,
            detailCode: supplierOriginalCode || partNumber,
            detailName: itemName || '',
            name: itemName || '',
            stockPrice: newStockPrice,
            sellingPrice: newSellingPrice,
            quantity,
            purchaseSum: quantity * newStockPrice,
            sellingSum: quantity * newSellingPrice
        });
    };

    // -------------------------------------
    // Render
    // -------------------------------------

    render() {
        const { mapperData, id, isMobile, counterpartId, documentType } = this.props;
        const {
            storageProducts,
            alertModalVisible,
            brandId,
            detailCode,
            detailName,
            uktz,
            stockPrice,
            quantity,
            storageBalance,
            sellingPrice,
            addToAddress,
            getFromAddress,
            comment,
            showCellModal,
            showFromCellModal,
            visible,
            orderId,
            loading,
            docProductUnitId,
            detailCatalogueFormDocs,
            purchaseSum,
            sellingSum
        } = this.state;

        // For clarity, we can compute sums in the component’s state methods instead of inline:
        // const purchaseSum = Math.round(quantity * stockPrice * 10000) / 10000;
        // const sellingSum = Math.round(quantity * (/* ... */) * 10000) / 10000;

        // or rely on the 'purchaseSum' and 'sellingSum' we store in state.

        const sellingSumData = mapperData.sellingPriceEqualsPurchasePrice
            ? stockPrice
            : mapperData.sellingPriceDisabled
            ? 0
            : sellingPrice;

        return (
            <Modal
                destroyOnClose
                footer={
                    <div>
                        <Button onClick={this.handleCancel}>
                            <FormattedMessage id='stock_table.button.cancel' />
                        </Button>
                        <Button loading={loading} onClick={this.handleOk} type='primary'>
                            <FormattedMessage id='ok' />
                        </Button>
                    </div>
                }
                maskClosable={false}
                onCancel={this.handleCancel}
                open={this.props.visible}
                width='fit-content'
                zIndex={200}
            >
                {/* First row with detailCode, brand, order, fromCell, toCell, quantity, purchasePrice, sellingPrice */}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: isMobile && 'wrap',
                        justifyContent: !isMobile ? 'space-between' : 'end',
                        alignItems: 'flex-end',
                        margin: '24px 0 0 0',
                        gap: 4
                    }}
                >
                    {/* detailCode */}
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ width: isMobile && 'calc(100% - 24px)' }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.detail_code' />}
                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.detail_code' />}>
                            <AutoComplete
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                onChange={value => {
                                    // If user is typing the code, reset brand/tradeCode
                                    this.setState({
                                        detailCode: String(value).replace(/[^A-Za-z0-9\u0400-\u04FF]/g, ''),
                                        tradeCode: undefined
                                    });
                                    if (value.length >= 3) {
                                        this.debouncedQuerySearch(value);
                                    }
                                }}
                                onSelect={value => {
                                    // This means user picked an item from the list by ID
                                    this.getProductId(undefined, undefined, value);
                                }}
                                placeholder={
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <FormattedMessage id='order_form_table.detail_code' />
                                        </div>
                                        <div style={{ color: 'red' }}>*</div>
                                    </div>
                                }
                                style={{ minWidth: !isMobile ? 160 : '89%' }}
                                value={detailCode}
                            >
                                {storageProducts.map(elem => {
                                    return (
                                        <Option key={elem.id} value={elem.id}>
                                            {elem.code}
                                        </Option>
                                    );
                                })}
                            </AutoComplete>
                        </Tooltip>
                    </div>
                    {/* Stock Icon button to open the “stock products” modal */}
                    <Button
                        icon={<StockIcon />}
                        onClick={() => this.setState({ visible: true })}
                        style={{ width: isMobile && '24px' }}
                    />

                    {/* Brand */}
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ minWidth: !isMobile ? 140 : '100%' }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.brand' />}
                        <Tooltip placement='top' title={<FormattedMessage id='order_form_table.brand' />}>
                            <Select
                                dropdownStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: !isMobile ? 220 : 'auto'
                                }}
                                filterOption={(input, option) => {
                                    return (
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        String(option.value).indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onBlur={() => {
                                    this.setState({ brandSearchValue: '' });
                                }}
                                onSearch={input => {
                                    this.setState({ brandSearchValue: input });
                                }}
                                onSelect={(value, option) => {
                                    this.setState({
                                        brandId: value,
                                        tradeCode: undefined,
                                        brandName: option.children
                                    });
                                }}
                                placeholder={
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <FormattedMessage id='order_form_table.brand' />
                                        </div>
                                        <div style={{ color: 'red' }}>*</div>
                                    </div>
                                }
                                showSearch
                                style={{ maxWidth: !isMobile && 140 }}
                                value={brandId}
                            >
                                {this.props.brands.map((elem, index) => (
                                    <Option key={index} supplier_id={elem.supplierId} value={elem.brandId}>
                                        {elem.brandName}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    </div>

                    {/* Directory orders */}
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ minWidth: !isMobile ? 80 : '100%', width: 329 }}
                    >
                        {isMobile && <FormattedMessage id='directories.orders' />}
                        <Tooltip title={<FormattedMessage id='directories.orders' />}>
                            <Input
                                allowClear
                                className={Styles.inputStoreDocService}
                                onChange={() => {
                                    this.setState({ orderId: undefined });
                                }}
                                onClick={() => {
                                    this.props.saveModal();
                                    this.props.setModal(MODALS.SELECT_ORDER, {
                                        onSubmit: value => this.setState({ orderId: value }),
                                        onClose: () => {
                                            this.props.loadModal(MODALS.STORE_DOC_SERVICE);
                                        }
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'store_doc_service.source_tooltip'
                                })}
                                value={orderId}
                            />
                        </Tooltip>
                    </div>

                    {/* WMS from_cell (if expense or transfer) */}
                    {(this.props.type === constants.EXPENSE || this.props.type === constants.TRANSFER) && (
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ minWidth: !isMobile ? 80 : '100%', width: 255 }}
                        >
                            {isMobile && <FormattedMessage id='wms.from_cell' />}
                            <Tooltip placement='top' title={<FormattedMessage id='wms.from_cell' />}>
                                <Input
                                    allowClear
                                    onChange={e => {
                                        if (!e.target.value) {
                                            this.setState({ getFromAddress: null });
                                        }
                                    }}
                                    onClick={() => this.setState({ showFromCellModal: true })}
                                    value={getFromAddress}
                                />
                                <WMSCellsModal
                                    confirmAction={cellAddress => this.setState({ getFromAddress: cellAddress })}
                                    fixedWarehouse
                                    hideModal={() => this.setState({ showFromCellModal: false })}
                                    placeholder={this.props.intl.formatMessage({ id: 'wms.from_cell' })}
                                    visible={Boolean(showFromCellModal)}
                                    warehouseId={this.props.warehouseId}
                                />
                            </Tooltip>
                        </div>
                    )}

                    {/* WMS cell (if income, orderIncome, or transfer) */}
                    {(this.props.type === constants.INCOME ||
                        this.props.documentType === constants.ORDERINCOME ||
                        this.props.type === constants.TRANSFER) && (
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ minWidth: !isMobile ? 80 : '100%' }}
                        >
                            {isMobile && <FormattedMessage id='wms.cell' />}
                            <Tooltip placement='top' title={<FormattedMessage id='wms.cell' />}>
                                <Input
                                    allowClear
                                    onChange={e => {
                                        if (!e.target.value) {
                                            this.setState({ addToAddress: null });
                                        }
                                    }}
                                    onClick={() => this.setState({ showCellModal: true })}
                                    placeholder={this.props.intl.formatMessage({ id: 'wms.cell' })}
                                    value={addToAddress}
                                />
                                <WMSCellsModal
                                    confirmAction={cellAddress => this.setState({ addToAddress: cellAddress })}
                                    fixedWarehouse
                                    hideModal={() => this.setState({ showCellModal: false })}
                                    visible={Boolean(showCellModal)}
                                    warehouseId={this.props.incomeWarehouseId}
                                />
                            </Tooltip>
                        </div>
                    )}

                    {/* Quantity */}
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ marginRight: isMobile && 4 }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.count' />}
                        <span className={Styles.priceslNumberInput}>
                            <Tooltip placement='top' title={<FormattedMessage id='order_form_table.count' />}>
                                <InputNumber
                                    decimalSeparator=','
                                    formatter={numeralFormatter}
                                    max={this.props.maxOrdered ? storageBalance[3].count : undefined}
                                    min={0.0001}
                                    onChange={val => {
                                        // If quantity changes, recalc sums
                                        const { stockPrice, sellingPrice } = this.state;
                                        this.setState({
                                            quantity: val,
                                            purchaseSum: val * stockPrice,
                                            sellingSum: val * sellingPrice
                                        });
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={numeralParser}
                                    precision={4}
                                    style={{ fontSize: 13 }}
                                    value={quantity}
                                />
                            </Tooltip>
                        </span>
                    </div>

                    {/* Purchase Price (stockPrice) */}
                    <div
                        className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                        style={{ marginRight: isMobile && 4 }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.purchasePrice' />}
                        <span className={Styles.priceslNumberInput}>
                            <Tooltip placement='top' title={<FormattedMessage id='order_form_table.purchasePrice' />}>
                                <InputNumber
                                    controls={false}
                                    decimalSeparator=','
                                    disabled={mapperData.purchasePriceDisabled || this.props.maxOrdered}
                                    formatter={numeralFormatter}
                                    min={0}
                                    onChange={val => {
                                        const { quantity } = this.state;
                                        this.setState({
                                            stockPrice: val,
                                            purchaseSum: val * quantity
                                        });
                                    }}
                                    onStep={() => this.setState({})}
                                    parser={numeralParser}
                                    precision={4}
                                    style={{ fontSize: stockPrice > 999 ? 11 : 13 }}
                                    value={stockPrice}
                                />
                            </Tooltip>
                        </span>
                    </div>

                    {/* Selling Price */}
                    <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                        {isMobile && <FormattedMessage id='storage_document.sell_price' />}
                        <span className={Styles.priceslNumberInput}>
                            <Tooltip placement='top' title={<FormattedMessage id='storage_document.sell_price' />}>
                                <InputNumber
                                    controls={false}
                                    decimalSeparator=','
                                    disabled={this.props.maxOrdered || mapperData.sellingPriceDisabled}
                                    formatter={numeralFormatter}
                                    min={0}
                                    onChange={val => {
                                        const { quantity } = this.state;
                                        this.setState({
                                            sellingPrice: val,
                                            sellingSum: val * quantity
                                        });
                                    }}
                                    parser={numeralParser}
                                    precision={4}
                                    style={{ fontSize: stockPrice > 999 ? 11 : 13 }}
                                    value={
                                        mapperData.sellingPriceEqualsPurchasePrice
                                            ? stockPrice
                                            : mapperData.sellingPriceDisabled
                                            ? 0
                                            : sellingPrice
                                    }
                                />
                            </Tooltip>
                        </span>
                    </div>
                </div>

                {/* Second row with detailName, tradeCode, docProductUnitId, purchaseSum, sellingSum, etc. */}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: isMobile && 'wrap',
                        alignItems: 'flex-end',
                        margin: !isMobile ? '8px 0 8px 0' : '12px 0 12px 0',
                        justifyContent: !isMobile ? 'space-between' : 'end',
                        gap: 4
                    }}
                >
                    {this.props.type == constants.INCOME && !this.props.priceDisabled && (
                        <div
                            style={{
                                minWidth: !isMobile ? 140 : '100%',
                                width: 345,
                                maxWidth: 345
                            }}
                        >
                            <Tooltip
                                placement='bottom'
                                title={
                                    <div>
                                        <FormattedMessage id='order_form_table.detail_code' /> (
                                        <FormattedMessage id='storage.supplier' />)
                                    </div>
                                }
                            >
                                <Input
                                    disabled
                                    placeholder={`${this.props.intl.formatMessage({
                                        id: 'order_form_table.detail_code'
                                    })} (${this.props.intl.formatMessage({
                                        id: 'storage.supplier'
                                    })})`}
                                    readOnly
                                    style={{
                                        color: 'black'
                                    }}
                                />
                            </Tooltip>
                        </div>
                    )}
                    {/* detailName */}
                    <div
                        style={{
                            minWidth: !isMobile ? 140 : '100%',
                            width: 328,
                            maxWidth: 328
                        }}
                    >
                        {isMobile && <FormattedMessage id='order_form_table.detail_name' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='order_form_table.detail_name' />}>
                            <Input
                                disabled
                                placeholder={this.props.intl.formatMessage({
                                    id: 'order_form_table.detail_name'
                                })}
                                readOnly
                                style={{ color: 'black' }}
                                value={detailName}
                            />
                        </Tooltip>
                    </div>

                    {/* Trade code */}
                    <div className={Styles.lowerBlockStyle} style={{ minWidth: isMobile && '100%' }}>
                        {isMobile && <FormattedMessage id='storage.trade_code' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='storage.trade_code' />}>
                            <InputNumber
                                controls={false}
                                formatter={val => val?.replace(/\D/g, '')}
                                min={0}
                                onChange={val => this.setState({ uktz: val })}
                                parser={val => val.replace(/\D/g, '')}
                                placeholder={this.props.intl.formatMessage({ id: 'storage.trade_code' })}
                                stringMode
                                style={{ color: 'black', width: '100%' }}
                                value={uktz}
                            />
                        </Tooltip>
                    </div>
                    {!(this.props.type == constants.INCOME && !this.props.priceDisabled) && (
                        <div
                            style={{
                                minWidth: !isMobile ? 140 : '100%',
                                width: 450,
                                maxWidth: 345
                            }}
                        ></div>
                    )}

                    {/* docProductUnitId (units) */}
                    <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                        {isMobile && <FormattedMessage id='services_table.units' />}
                        <Tooltip placement='bottom' title={<FormattedMessage id='services_table.units' />}>
                            <Select
                                allowClear
                                getPopupContainer={trigger => trigger.parentNode}
                                onSelect={value => this.setState({ docProductUnitId: value })}
                                placeholder={this.props.intl.formatMessage({ id: 'services_table.units_placeholder' })}
                                popupMatchSelectWidth={100}
                                showSearch
                                style={{ width: 86, color: 'var(--text)', textAlign: 'right', marginRight: 4 }}
                                value={docProductUnitId}
                            >
                                {(this.props.units || []).map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.shortcut}
                                    </Option>
                                ))}
                            </Select>
                        </Tooltip>
                    </div>

                    {/* purchaseSum (now editable) */}
                    {!this.props.priceDisabled && (
                        <div
                            className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}
                            style={{ maxWidth: '180px' }}
                        >
                            {isMobile && <FormattedMessage id='purchase_sum' />}
                            <span className={Styles.priceslNumberInput}>
                                <Tooltip placement='bottom' title={<FormattedMessage id='purchase_sum' />}>
                                    <InputNumber
                                        controls={false}
                                        decimalSeparator=','
                                        formatter={numeralFormatter}
                                        onChange={val => this.handlePurchaseSumChange(val)}
                                        parser={numeralParser}
                                        precision={4}
                                        style={{
                                            color: 'black',
                                            fontSize: purchaseSum > 999 ? 11 : 13
                                        }}
                                        value={purchaseSum}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    )}

                    {/* sellingSum (now editable) */}
                    {!this.props.priceDisabled && (
                        <div className={!isMobile ? Styles.addProductItemWrap : Styles.addProductItemWrapMobile}>
                            {isMobile && <FormattedMessage id='storage_gocument.sell_sum' />}
                            <span className={Styles.priceslNumberInput}>
                                <Tooltip placement='bottom' title={<FormattedMessage id='storage_gocument.sell_sum' />}>
                                    <InputNumber
                                        controls={false}
                                        decimalSeparator=','
                                        formatter={numeralFormatter}
                                        onChange={val => this.handleSellingSumChange(val)}
                                        parser={numeralParser}
                                        precision={4}
                                        style={{
                                            color: 'black',
                                            fontSize: sellingSum > 999 ? 11 : 13
                                        }}
                                        value={sellingSum}
                                    />
                                </Tooltip>
                            </span>
                        </div>
                    )}
                </div>

                {/* Third row with comment & balances */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 8
                    }}
                >
                    {/* optional product comment */}
                    {mapperData.allowProductComment && (
                        <div>
                            <div style={{ maxWidth: 250, marginRight: 5 }}>
                                <div>
                                    <FormattedMessage id='comment' />
                                </div>
                                <TextArea
                                    allowClear
                                    onChange={e => this.setState({ comment: e.target.value })}
                                    placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                    value={comment}
                                />
                            </div>
                        </div>
                    )}

                    {/* Balances (only if type === ORDER) */}
                    <div style={{ display: 'flex' }}>
                        {this.props.type === constants.ORDER && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: isMobile && 'wrap',
                                    justifyContent: isMobile ? 'space-between' : 'flex-start'
                                }}
                            >
                                {storageBalance.map((elem, key) => {
                                    const message =
                                        this.props.intl.formatMessage({ id: elem.messageId }) || elem.messageId;

                                    return (
                                        <div
                                            key={key}
                                            className={
                                                !isMobile ? Styles.addProductItemWrap : Styles.addProducOrderWrapMobile
                                            }
                                        >
                                            <Tooltip placement='bottom' title={message}>
                                                <InputNumber
                                                    decimalSeparator=','
                                                    readOnly
                                                    style={{ color: 'black' }}
                                                    value={elem.count}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>

                {/* detail catalogue modal */}
                <DetailCatalogueModal
                    addDocProduct={this.props.addDocProduct}
                    brandId={brandId}
                    brands={this.props.brands}
                    clientId={documentType === constants.CLIENT ? counterpartId : undefined}
                    code={detailCode}
                    detailCatalogueFormDocs={detailCatalogueFormDocs}
                    handleCancel={this.props.hideModal}
                    hideModal={() => this.setState({ visible: undefined })}
                    id={id}
                    onSelect={this.selectProduct}
                    setSupplier={this.setSupplier}
                    user={this.props.user}
                    visible={Boolean(visible)}
                    warehouseId={this.props.warehouseId}
                />

                {/* confirm creation of new product if not found */}
                <AddStoreProductModal
                    alertVisible={alertModalVisible}
                    cancelAlertModal={this.cancelAlertModal}
                    confirmAlertModal={this.confirmAlertModal}
                    {...this.state}
                >
                    <FormattedMessage id='storage_document.error.product_not_found' />
                </AddStoreProductModal>
            </Modal>
        );
    }
}

export default AddProductModal;
