import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, Tooltip } from 'antd';
import { Layout } from 'commons';
import { MODALS, setModal } from 'core/modals/duck';
import { selectLinks } from 'core/storage/products';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { VehicleModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import CompoundFilterDrawer from './components/CompoundFilterDrawer';
import VehiclesTable from './components/VehiclesTable';
import { fetchVehicles, selectFilters } from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    allLinks: selectLinks(state),
    filters: selectFilters(state)
});

const mapDispatchToProps = {
    fetchVehicles,
    setModal
};

/**
 * This page was created to maintain all vehicles of the station(show tables, subtables, controls and other).
 * There is used "feature first" files structure(reffer to react documentaion), it means
 * that each one-time used component can be found deeper in a tree structure
 *
 * Each subcomponent is self-sufficient and requires only necessary data from parrent,
 * other data can be fetched and stored by redux saga/duck.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class VehiclesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLinks: [],
            helperDrawerOpen: false,
            drawerOpen: false,
            // Key used to force re-mount of CompoundFilterDrawer:
            resetKey: Date.now()
        };
    }

    componentDidMount() {
        this.checkIsFiltered();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.drawerOpen !== prevState.drawerOpen) {
            this.checkIsFiltered();
        }
    }

    checkIsFiltered = () => {
        const savedData = localStorage.getItem('drawerCompoundFiltersData');
        let hasFilters = false;

        if (savedData) {
            try {
                const parsed = JSON.parse(savedData);
                if (parsed?.filters?.length > 0) {
                    hasFilters = true;
                }
            } catch (e) {
                console.warn('Error parsing local filters:', e);
            }
        }

        this.setState({ hasFilters });
    };

    onAddVehicle = () => {
        this.props.setModal(MODALS.VEHICLE, {
            mode: 'ADD',
            onClose: () => this.props.fetchVehicles()
        });
    };

    fetchHelperLinks = async () => {
        const links = await fetchAPI('GET', '/helps', { helpId: 'directories_and_settings_vehicles' }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            allLinks: links
        });
    };

    downloadXLSX = async () => {
        const { filters } = this.props;
        let compoundFilters;

        const savedData = localStorage.getItem('drawerCompoundFiltersData');
        if (savedData) {
            try {
                const parsed = JSON.parse(savedData);
                // parsed.filters.forEach(obj => {
                //     Object.keys(obj).forEach(key => {
                //         if (Array.isArray(obj[key]) && obj[key].length === 0) {
                //             obj[key] = null;
                //         }
                //     });
                // });
                if (parsed?.filters?.length > 0) {
                    compoundFilters = parsed;
                }
            } catch (e) {
                console.warn('Error parsing local filters:', e);
            }
        }

        const url = '/clients/vehicles/report';
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    filters,
                    compoundFilters: compoundFilters?.filters
                },

                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true,
                    ignoreOmit: true
                }
            );

            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            await saveAs(reportFile, fileName);
            this.handleCancel();
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    render() {
        const { user, fetchVehicles } = this.props;
        const { helperDrawerOpen, allLinks, drawerOpen, resetKey, hasFilters } = this.state;

        return (
            <Layout
                controls={
                    <div style={{ display: 'flex', gap: 8 }}>
                        {/* 1) Open Filter Button */}
                        {isGrantAccessed(user, grants.CLIENTS_VEHICLES_REPORT) && (
                            <Tooltip title={<FormattedMessage id='report_cash_flow_page.download_full_report' />}>
                                <Button icon={<DownloadOutlined />} onClick={this.downloadXLSX} />
                            </Tooltip>
                        )}
                        {isGrantAccessed(user, grants.CLIENTS_VEHICLES_COMPOUND_FILTER) && (
                            <React.Fragment>
                                <Button
                                    onClick={() => {
                                        this.setState({ drawerOpen: true });
                                    }}
                                >
                                    <FormattedMessage id='compound_filtes' />
                                </Button>

                                {/* 2) Reset Filters Button */}
                                <Button
                                    onClick={() => {
                                        // Remove local storage item so next time the child mounts, no filters are restored
                                        localStorage.removeItem('drawerCompoundFiltersData');
                                        // Force the child to re-mount by changing its key
                                        this.setState({
                                            drawerOpen: false,
                                            resetKey: Date.now(),
                                            hasFilters: false
                                        });
                                        fetchVehicles();
                                    }}
                                >
                                    <FormattedMessage id='detail_order.reset_filters' />
                                </Button>
                            </React.Fragment>
                        )}

                        <Button
                            disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST, accesses.ROWO)}
                            onClick={this.onAddVehicle}
                            type='primary'
                        >
                            <FormattedMessage id='vehicles_page.add_vehicle' />
                        </Button>

                        <div style={{ display: 'flex' }}>
                            <Button
                                disabled={!isGrantAccessed(user, grants.DIRECTORIES_CARS_LIST, accesses.ROWO)}
                                icon={<QuestionCircleOutlined />}
                                onClick={async () => {
                                    const links = await fetchAPI(
                                        'GET',
                                        '/helps',
                                        { helpId: 'directories_and_settings_vehicles' },
                                        undefined,
                                        { handleErrorInternally: true }
                                    );
                                    this.setState({
                                        allLinks: links,
                                        helperDrawerOpen: true
                                    });
                                }}
                                style={{ fontSize: 22, display: 'flex', justifyContent: 'center' }}
                                type='text'
                            />
                        </div>
                    </div>
                }
                description={<FormattedMessage id='vehicles_page.description' />}
                title={<FormattedMessage id='vehicles_page.title' />}
            >
                <VehiclesTable fetchHelperLinks={this.fetchHelperLinks} hasFilters={hasFilters} />
                <VehicleModal />

                {/* Helper Drawer */}
                <Drawer
                    onClose={() => {
                        this.setState({ helperDrawerOpen: false });
                    }}
                    open={helperDrawerOpen}
                    title={<FormattedMessage id='navigation.helper' />}
                    width={420}
                >
                    <div>
                        {allLinks.map(({ ogUrl, ogTitle, ogDescription, ogImage }, index) => (
                            <div key={index} className={Styles.linkBlock}>
                                <div className={Styles.ogTitle}>
                                    {index + 1}. {ogTitle}
                                </div>
                                <div className={Styles.ogDesc}>{ogDescription}</div>
                                <div className={Styles.ogImg}>
                                    <Image
                                        src={
                                            _.isArray(ogImage)
                                                ? _.get(ogImage, '[0].url', [])
                                                : _.get(ogImage, 'url', [])
                                        }
                                    />
                                </div>
                                <a href={ogUrl} rel='noreferrer' target='_blank'>
                                    <Button style={{ width: '100%' }} type='primary'>
                                        <FormattedMessage id='repair_map_table.goto' />
                                    </Button>
                                </a>
                            </div>
                        ))}
                    </div>
                </Drawer>

                {/* The CompoundFilterDrawer, forced to re-mount with resetKey */}
                <CompoundFilterDrawer
                    key={resetKey}
                    drawerOpen={drawerOpen}
                    onClose={() => this.setState({ drawerOpen: false })}
                    onOk={() => {
                        fetchVehicles();
                    }}
                />
            </Layout>
        );
    }
}
